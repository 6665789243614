<template>
  <MyView ref="view" :meta="{ viewClass: 'media-visor', bodyClass: `media-visor-body transparent`, inFx: 'fadeIn' }" v-if="visible">
    <Overlay class="loading loading-logo" v-if="loading" />
    <PostMediaSlider class="media-visor-slider" v-if="items">
      <div v-for="(media, index) in items" :key="`media-${index}`" :class="` file creator-media-wrap`">
        <img alt="Image" v-if="mediaType(media) === 'image'" :src="media.src" />
        <video v-else-if="mediaType(media) === 'video'" width="100%" controls playsinline :poster="media.thumb" controlslist="nodownload">
          <source :src="`${media.src}`" />
        </video>
      </div>
    </PostMediaSlider>
    <div class="media-visor-slider-options">
      <a class="button" @click="closeVisor"><iconic name="times" /></a>
    </div>
  </MyView>
</template>

<script>
import MyView from "../../views/View.vue";
import PostMediaSlider from "./PostMediaSlider.vue";
import Overlay from "./Overlay.vue";
export default {
  components: { MyView, PostMediaSlider, Overlay },
  data: function() {
    return {
      loading: false,
      visible: false,
      items: null,
    };
  },
  methods: {
    closeVisor: function() {
      this.$refs.view.close(() => {
        this.visible = false;
      });
    },
    openVisor: function() {
      this.visible = true;
    },
  },
  created: function() {
    this.$root.$on("MediaVisor", ({ media }) => {
      this.visible = true;
      this.items = media;
    });
  },
};
</script>

<style lang="scss">
.media-visor {
  z-index: 13;
  &-body {
    @include Flex(inherit, center, center);
    @include Glass(#000, 0.7);
  }
  &-slider {
    .slick-track {
      background: rgba(0, 0, 0, 0);
    }
    .slick-slide {
      height: 100%;
      max-height: 100%;
      > div,
      .file-media {
        max-height: 100%;
        height: 100%;
      }
      .creator-media-wrap img,
      .creator-media-wrap video {
        width: auto;
        margin: auto;
        max-height: 100vh;
      }
    }
    &-options {
      position: fixed;
      top: $mpadding;
      left: $mpadding;
      z-index: 2;
      .button {
        @include Glass(#000, 0.5);
        @include Flex(inherit, center, center);
        width: $mpadding * 3;
        height: $mpadding * 3;
        color: #fff;
        border-radius: 50%;
        cursor: pointer;
        &:active,
        &:hover {
          @include Glass(#fff, 0.5);
        }
      }
    }
  }
}
</style>
